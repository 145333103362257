@charset 'utf-8';
@import 'mixin';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/slick';
@import 'components/slick-theme';
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
$noir: #636363;
$snow: #ffffff;
$rose:#ef4a59;
$primary:#381C75;
$secondary:#FF285B;
$radius:25px;
// @font-face {
//     font-family: 'lato-light';
//     src: url('../font/lato-light-webfont.eot');
//     src: url('../font/lato-light-webfont.eot?#iefix') format('embedded-opentype'), url('../font/lato-light-webfont.woff') format('woff'), url('../font/lato-light-webfont.ttf') format('truetype'), url('../font/lato-light-webfont.svg#lato-light') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'lato';
//     src: url('../font/lato-regular-webfont.eot');
//     src: url('../font/lato-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../font/lato-regular-webfont.woff') format('woff'), url('../font/lato-regular-webfont.ttf') format('truetype'), url('../font/lato-regular-webfont.svg#lato-regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'lato-bold';
//     src: url('../font/lato-bold-webfont.eot');
//     src: url('../font/lato-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../font/lato-bold-webfont.woff') format('woff'), url('../font/lato-bold-webfont.ttf') format('truetype'), url('../font/lato-bold-webfont.svg#lato-bold') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'lato-black';
//     src: url('../font/lato-black-webfont.eot');
//     src: url('../font/lato-black-webfont.eot?#iefix') format('embedded-opentype'), url('../font/lato-black-webfont.woff') format('woff'), url('../font/lato-black-webfont.ttf') format('truetype'), url('../font/lato-black-webfont.svg#lato-black') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700');
@font-face {
    font-family: 'skolasansbold';
    src: url('../font/skolasans-bold.eot');
    src: url('../font/skolasans-bold.eot?#iefix') format('embedded-opentype'), url('../font/skolasans-bold.woff2') format('woff2'), url('../font/skolasans-bold.woff') format('woff'), url('../font/skolasans-bold.ttf') format('truetype'), url('../font/skolasans-bold.svg#skolasansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'skolasansregular';
    src: url('../font/skolasans-regular.eot');
    src: url('../font/skolasans-regular.eot?#iefix') format('embedded-opentype'), url('../font/skolasans-regular.woff2') format('woff2'), url('../font/skolasans-regular.woff') format('woff'), url('../font/skolasans-regular.ttf') format('truetype'), url('../font/skolasans-regular.svg#skolasansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.formulaire_crayon .crayon-active {
    font-size: 15px!important;
    font-family: 'Raleway'!important;
    color: $noir!important;
        // min-height: 250px!important;
        p{
            max-width: 100%!important;
        }
    }


    body {
        color: $noir;
        font-family: 'Open Sans';
        font-weight: 300;
    /*@include font-size();*/
}

main {
    display: block;
}

.uppercase{
    text-transform: uppercase;
}

.h1_hidden {
    font-size: 0px !important;
    text-indent: -99999999px !important;
}

.slick-prev:before,
.slick-next:before {
    color: $primary;
    @include font-size(30px);
}

.slick-next {
    right: 15px;
    top: 50%;
}

.slick-prev {
    left: 15px;
    top: 50%;
}

.slick-slide {
    outline: none;
}

.bold {
    font-weight: 700;
}

.row_100 {
    max-width: 80%!important;
}

.row_200 {
    max-width: 100%!important;
}

.width_100 {
    width: 100%!important;
}

.aligne_center {
    text-align: center;
}

.margin_section {
    margin-top: 70px!important;
    margin-bottom: 70px!important;
}

.padding_section {
    padding-top: 70px!important;
    padding-bottom: 70px!important;
}

.clear {
    clear: both;
}

.parent {
    position: relative;
    width: 50%;
    height: 200px;
    margin: 20px auto;
}

.child {
    padding: 20px;
    &.both {
        @include center;
    }
    &.horizontal {
        @include center(true,
            false);
    }
    &.vertical {
        @include center(false,
            true);
    }
}

.shareca {
    margin-bottom: 10px;
    span {
        @include transition(filter .3s);
        @include filter (grayscale, 100%);
        &:hover {
            @include filter (grayscale, 0%);
        }
    }
}

h1,
h2,
h3,
h4 h5,
h6 {}

H1 {
    /*@include font-size();
        font-family: '';
        color: ;*/
    }

    h2 {
        @include font-size(40px);
        font-family: 'skolasansbold';
    // text-transform: ;
    color: $primary;
}

h3 {
    /*@include font-size();
        font-family: '';
        color: ;*/
    }

    h4 {
    /*@include font-size();
        font-family: '';*/
    }

    h5 {
    /*@include font-size();
        font-family: '';*/
    }

    li {
        list-style: none!important;
    }

    .footer_13px {
        clear: both;
        a {
            @include font-size(13px);
            text-align: center;
            width: 100%;
            display: block;
            padding: 10px;
            color: $noir;
            @include transition (.3s);
            &:hover {
                color: $rose;
                .heartbeat {
                    &:after,
                    &:before {
                        position: absolute;
                        content: "";
                        background: $rose;
                    }
                }
            }
        }
    }

    .cta,
    form input[type="submit"] {
        @include linear-gradient(45deg,
            $secondary 30%,
            $primary 200%);
        @include font-size(20px);
        font-weight: 800;
        color: $snow;
        padding: 10px 40px 15px;
    // @include border-radius(8px);
    @include transition(.3s);
    display: inline-block;
    border: none;
    text-transform: uppercase;
    &:hover {
        color: $snow;
        @include box-shadow(5px,
            5px,
            10px,
            rgba($noir,
                .3));
        @include transform(scale(1.1));
    }
    &:focus {
        color: $snow;
    }
}

#404 {
    header {
        min-height: 100%;
    }
}

// HEADER --------------------------------------------------------------------------------------------

/*BURGER*/

$nav-size: 50px;
$nav-color: #fff;
$nav-color-active: #445566;
$hasBackground: true;
// Advanced Config
$animation-time: 300ms;
$animation-easing: ease-in-out;
$z-index-navigation-button: 500;
$z-index-navigation-inner: $z-index-navigation-button + 25;
@if $hasBackground==false {
    $nav-color: $nav-color-active;
}

* {
    box-sizing: border-box;
}

.material--burger {
    cursor: pointer;
    padding: ($nav-size / 4);
    position: relative;
    width: $nav-size;
    height: $nav-size;
    background: rgba($snow, .8);
    @if $hasBackground==true {
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $primary;
            animation: material-menu-background $animation-time $animation-easing forwards;
        }
    }
    span {
        top: 50%;
        transform-origin: center center;
        animation-name: material-menu;
        &,
        &:before,
        &:after {
            width: ($nav-size / 2);
            background-color: $nav-color;
            height: ceil($nav-size / 20);
            position: absolute;
            z-index: $z-index-navigation-inner;
            animation-duration: $animation-time;
            animation-fill-mode: forwards;
            animation-timing-function: $animation-easing;
            transition: background-color $animation-time $animation-easing;
        }
        &:before,
        &:after {
            content: '';
            display: block;
        }
        &:before {
            top: 300%;
            transform-origin: right bottom;
            animation-name: material-menu-before;
        }
        &:after {
            bottom: 300%;
            transform-origin: right top;
            animation-name: material-menu-after;
        }
    }
    &.material--arrow {
        @if $hasBackground==true {
            &:after {
                animation: material-arrow-background $animation-time $animation-easing forwards;
            }
        }
        span,
        span:before,
        span:after {
            animation-duration: $animation-time;
            animation-fill-mode: forwards;
            animation-timing-function: $animation-easing;
            background-color: $primary;
        }
        span {
            animation-name: material-arrow;
        }
        span:before {
            animation-name: material-arrow-before;
        }
        span:after {
            animation-name: material-arrow-after;
        }
    }
}

@if $hasBackground==true {
    @keyframes material-arrow-background {
        100% {
            transform: rotate(180deg) scale(0);
        }
    }
    @keyframes material-menu-background {
        0% {
            transform: rotate(180deg) scale(0);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }
}

@keyframes material-arrow {
    100% {
        transform: rotate(270deg);
    }
}

@keyframes material-arrow-before {
    100% {
        top: 0;
        transform: rotate(-40deg) scaleX(0.7);
    }
}

@keyframes material-arrow-after {
    100% {
        bottom: 0;
        transform: rotate(40deg) scaleX(0.7);
    }
}

@keyframes material-menu {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes material-menu-before {
    0% {
        top: 0;
        transform: rotate(-40deg) scaleX(0.7);
    }
    100% {
        top: 300%;
        transform: rotate(0) scaleX(1);
    }
}

@keyframes material-menu-after {
    0% {
        bottom: 0;
        transform: rotate(40deg) scaleX(0.7);
    }
    100% {
        bottom: 300%;
        transform: rotate(0) scaleX(1);
    }
}

.material--burger {
    /* margin: 90px auto;*/
}

.navigation--button {
    width: 20%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 20px;
    display: none;
    z-index: 9999;
}


/*BURGER*/

header {
    // min-height: 80vh;
    min-height: auto;
    .nav-principale {
        background: $snow;
        height: 110px;
        img {
            max-width: 200px;
        }
        .menu {
            ul {
                li {
                    width: calc(100% / 7);
                    display: block;
                    position: relative;
                    float: left;
                    .menu_contact {}
                    a {
                        @include font-size(13px);
                        padding-top: 40px;
                        width: 100%;
                        text-align: center;
                        display: block;
                        color: $noir;
                        font-weight: 800;
                        text-transform: uppercase;
                        @include transition (.3s);
                        &:hover {
                            color: $primary;
                        }
                    }
                    .active {
                        color: $secondary;
                        // &:after {
                        //     content: "";
                        //     width: 30px;
                        //     height: 40px;
                        //     background: url(../img/dent-menu.svg);
                        //     background-position: center;
                        //     background-size: cover;
                        //     @include center;
                        //     display: block;
                        //     position: absolute;
                        //     margin-top: 17px;
                        //     opacity: .4;
                        //     @include breakpoint(small down) {
                        //        top: 30%; 
                        //     }
                        // }
                    }
                    .menu_contact {
                        @include linear-gradient(45deg,
                            $primary 30%,
                            $secondary 150%);
                        color: $snow;
                        padding: 15px 0px;
                        // @include border-radius(8px);
                        @include transition(.3s);
                        // display: inline-block;
                        border: none;
                        margin-top: 23px;
                        &:hover {
                            color: $snow;
                            @include box-shadow(5px,
                                5px,
                                15px,
                                rgba($noir,
                                    .3));
                            @include transform(scale(1.05));
                        }
                    }
                }
            }
        }
    }
}

.intro_accueil {
    margin-top: 100px;
    padding-left: 30px;
    color: $snow;
    font-family: "skolasansbold";
    h2 {
        @include text-shadow;
        line-height: 40px;
        color: $snow;
    }
    p {
        margin: 30px auto;
    }
    .cta{
        margin-bottom: 30px!important;
    }
}

// HEADER --------------------------------------------------------------------------------------------
// MAIN --------------------------------------------------------------------------------------------
// article_une ===============================================================
.box_shadow {
    @include box-shadow(20px,
        20px,
        30px,
        rgba($noir,
            .1));
}

.article_une {
    .box {
        padding: 50px 40px;
        // border-radius: 50px;
    }
    p {
        font-weight: 600;
    }
    @include breakpoint(medium down){
        img{
            margin-top: 30px;
        }
    }
}

// article_une FIN ===========================================================
// specialisation_accueil ===============================================================
.specialisation_accueil {
    h2 {
        text-align: center;
        margin-bottom: 50px;
    }
    .bloc_specialisation {
        padding: 20px 30px;
        color: $noir;
        // @include border-radius($radius);
        @include transition(.3s);
        margin-bottom: 40px;
        background: $snow;
        display: block;
        &:hover {
            color: $snow;
            background: $secondary;
            @include transform(scale(1.03));
            .cta {
                background: $snow;
                color: $secondary;
            }
        }
        h3 {
            @include font-size(20px);
            font-weight: 800;
        }
        img {
            width: 30%;
            display: block;
            margin: 20px auto;
        }
        .cta {
            margin: 20px auto;
            text-align: center;
            display: inherit;
        }
    }
}

// specialisation_accueil FIN ===========================================================
// testimonials ===============================================================
.testimonials {
    background: $primary;
    text-align: center;
    color: $snow;
    p {
        font-style: italic;
    }
    h2 {
        color: $snow;
        margin-bottom: 25px;
    }
    hr {
        max-width: 80px;
        border-bottom: 3px solid $snow;
    }
    h3 {
        @include font-size( 25px);
    }
}

// testimonials FIN ===========================================================
// photos FIN ===========================================================
.photos {
    overflow: hidden;
    .slick-list {
        .slick-slide {
            // @include transition(.5s);
            width: auto;
            // opacity: .65;
            max-height: 450px;
            padding: 0;
        }
        .slick-current {
            // @include transition(.5s);
            opacity: 1;
        }
        .slick-track {
            min-height: 100%;
        }
    }
}

.photos2 {
    margin-left: 100px!important;
    position: absolute;
    @include breakpoint(small down) {
            position: relative;
            margin-left: -10px!important;
        }
    .slick-list {
        .slick-slide {
            @include transition(.5s);
            width: auto;
            opacity: 1;
            max-height: 450px;
            padding: 0;
        }
        .slick-current {
            @include transition(.5s);
            opacity: 1;
        }
        .slick-track {
            min-height: 100%;
        }
    }
}

// photos FIN ===========================================================
// prevoir  ===========================================================
.prevoir {
    h2 {
        margin-bottom: 30px;
        text-align: center;
    }
    ul {
        padding: 0;
        li {
            p{
                a{
                    @include font-size (22px);
                    font-weight: 800;
                    color: $noir;
                    &:hover{
                        color: $secondary;
                    }
                }
            }
        }
    }
    p {}
}

// prevoir FIN ===========================================================
// Equipe ===============================================================
.page_equipe_main {
    h2 {
        text-align: center;
        margin-bottom: 50px;

    }
    .prenom{
        min-height: 90px;
    }
    .item_equipe {
        float: left!important;
        margin-bottom: 50px;
        @include breakpoint(large up) {
            @include grid-column(4,
                80px);
        }
        h3 {
            text-align: center;
        }
        .bloc_equipe {
            overflow: hidden;
            padding: 20px 30px;
            color: $noir;
            // @include border-radius($radius);
            @include transition(.3s);
            margin-bottom: 20px;
            background: $snow;
            display: block;
            @include aspect-ratio(1,
                1);
            position: relative;
            padding: 0;
            @include breakpoint(large up) {
                &:hover {
                    // color: $snow;
                    // background: $secondary;
                    // @include transform(scale(1.03));
                    // .cta {
                    //     background: $snow;
                    //     color: $secondary;
                    // }
                    .bloc_vert {
                        background: rgba($secondary, .85);
                        @include transition(.3s);
                        p,
                        h3 {
                            margin-top: 0px;
                            max-height: 500px;
                        }
                    }
                }
            }
            h3 {
                @include font-size(20px);
                font-weight: 800;
            }
            .bloc_vert {
                @include transition(.5s);
                position: absolute;
                height: 100%;
                width: 100%;
                display: block;
                top: 0;
                text-align: center;
                // @include border-radius($radius);
                @include breakpoint(medium down) {
                    cursor: pointer;
                }
                h3 {
                    color: $snow;
                    text-shadow: 5px 7px 8px rgba(0, 0, 0, .1);
                    max-height: 0;
                    display: block;
                    position: relative;
                    overflow: hidden;
                    @include transition(.3s);
                    margin-top: -50px;
                }
                p {
                    margin-top: 50px;
                    color: $snow;
                    @include font-size(13px);
                    max-height: 0;
                    display: block;
                    position: relative;
                    overflow: hidden;
                    @include transition(.3s);
                }
            }
            @include breakpoint(medium down) {
                .bloc_open {
                    background: rgba($secondary, .85);
                    @include transition(.3s);
                    p,
                    h3 {
                        margin-top: 0px;
                        max-height: 500px;
                    }
                }
            }
        }
        // img {
        //     width: 30%;
        //     display: block;
        //     margin: 20px auto;
        // }
        // .cta {
        //     margin: 20px auto;
        //     text-align: center;
        //     display: inherit;
        // }
    }
}

// .listing_accueil {
//     .bloc_listing {
//         border: solid 4px $snow;
//         @include aspect-ratio(1,
//         1);
//         position: relative;
//         padding: 0;
//         &:hover {
//             .bloc_vert {
//                 p {
//                     padding-bottom: 20px;
//                     max-height: 500px;
//                 }
//             }
//         }
//         .bloc_vert {
//             @include transition(.3s);
//             position: absolute;
//             height: 100%;
//             width: 100%;
//             display: block;
//             top: 0;
//             text-align: center;
//             &:hover {
//                 background: rgba($primary, .6);
//             }
//             h3 {
//                 color: $snow;
//                 text-shadow: 5px 7px 8px rgba(0, 0, 0, .1);
//             }
//             p {
//                 color: $snow;
//                 @include font-size(18px);
//                 max-height: 0;
//                 display: block;
//                 position: relative;
//                 overflow: hidden;
//                 @include transition(.3s);
//             }
//         }
//     }
//     .bloc_listing_large {
//         @include aspect-ratio(16,
//         5);
//     }
// }
// Equipe FIN ===========================================================
// Article  ===========================================================
.intro_article {
    margin-top: 100px;
    padding-left: 30px;
    color: $snow;
    font-family: "skolasansbold";
    h2 {
        @include text-shadow;
        line-height: 40px;
        color: $snow;
        text-align: center;
    }
    p {
        margin: 30px auto;
    }
}

.article {
    .content_article {
        img {
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
            max-width: 750px;
            width: 100%;
        }
    }
}

// .formations {
//     .levre-bottom {}
//     h2 {}
//     h3 {
//         margin-bottom: 30px;
//         @include font-size(30px);
//     }
//     h4 {}
//     strong {
//         @include font-size(20px);
//         margin-bottom: 13px;
//         display: inline-block;
//     }
//     .liste_formations {
//         li {
//             margin-bottom: 20px;
//             list-style-type: circle!important;
//             @include font-size(23px);
//             font-weight: 900;
//             a {
//                 color: $bleu;
//                 @include transition(.3s);
//                 &:hover {
//                     color: $bleufonce;
//                 }
//                 strong {
//                     display: block;
//                     color: $orange;
//                 }
//             }
//         }
//     }
//     .les_fomrations {
//         .une_formation {
//             padding-top: 70px;
//             border-bottom: solid 1px $noir;
//             padding-bottom: 70px;
//             h3 {}
//             li {
//                 margin-bottom: 20px;
//                 list-style-type: circle!important;
//             }
//             .date {
//                 display: block;
//                 color: $orange;
//             }
//             .cta {
//                 margin-top: 50px;
//             }
//         }
//     }
// }
.news {
    .liste_news {
        ul {
            padding-left: 0;
            li {
                list-style: none!important;
                margin-bottom: 40px;
                @include font-size(23px);
                font-weight: 500;
                a {
                    color: $primary;
                    @include transition(.3s);
                    padding-left: 0;
                    padding: 20px;
                    &:hover {
                        background: $primary!important;
                        color: $snow;
                        .cta {
                            color: $primary;
                            background: $snow;
                        }
                        .txt_news {
                            color: $snow!important;
                            .txt_liste_news {
                                color: $snow!important;
                            }
                        }
                    }
                    h3 {
                        font-weight: 600;
                    }
                    .date {
                        display: block;
                        color: $secondary;
                        font-weight: 900;
                        @include font-size(18px);
                        margin-bottom: 20px;
                    }
                    .cta {
                        // display: inline;
                        // padding: 10px 20px;
                    }
                    .img_news {
                        // height: 200px;
                        margin-bottom: 0px;
                    }
                    .txt_news {
                        .txt_liste_news {
                            @include font-size(15px);
                            color: $noir;
                        }
                    }
                }
            }
        }
    }
}

// Article FIN ===========================================================
// contact ==============================================================================================
// contact ==============================================================================================
.contact {
    // padding-top: 0!important;
    text-align: center;
    padding-bottom: 0!important;
    color: $snow;
    .bg_contact {
        background: url(../img/bg-contact.svg);
        background-size: cover;
        background-position: center;
    }
    a {
        display: block;
        color: $snow!important;
        &:hover {
            color: rgba($snow, .7)!important;
        }
    }
    h2 {
        text-align: center;
        margin-bottom: 20px;
        color: $snow;
    }
    .facebook {
        // margin-top: 50px;
        a {
            @include transition(.3s);
            &:hover {
                img {
                    opacity: .5;
                }
            }
            img {
                @include transition(.3s);
                max-width: 80px;
                display: block;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }
    h3 {
        text-align: center;
    }
    ul {
        margin: 0;
        li {}
    }
    a {
        color: $noir;
        &:hover {
            color: $primary;
        }
    }
}

.map {
    position: relative;
    padding: 0;
    #googleMap1 {
        position: relative!important;
        width: 100%;
        height: 590px !important;
        display: block;
    }
}


/* ============================== FORM ================================= */

label {
    color: $primary !important;
    text-align: left!important;
}

form {
    fieldset {
        border: none!important;
        margin: 0!important;
        padding: 0!important;
    }
    input {
        min-height: 43px!important;
        @include font-size (18px);
    }
    textarea {
        min-height: 131px!important;
        @include font-size (18px);
    }
    label {
        font-weight: 700!important;
        @include font-size (18px);
    }
    input[type="submit"] {
        margin-top: 15px!important;
        font-weight: normal!important;
        margin-left: 16px!important;
        border: none;
    }
}


/* ============================== FORM ================================= */

// contact FIN ==============================================================================================
// contact FIN ==============================================================================================
// MAIN FIN ==============================================================================================
// MAIN FIN ==============================================================================================
// MAIN FIN ==============================================================================================
// MAIN FIN ==============================================================================================
// MAIN FIN ==============================================================================================
// footer ===============================================================================================
// MAIN FIN --------------------------------------------------------------------------------------------
// footer ===============================================================================================
.map {
    position: relative;
    #googleMap1 {
        position: relative!important;
        width: 100%;
        height: 400px !important;
        display: block;
    }
}

// footer ===============================================================================================
@include breakpoint(950px down) {
    .intro_accueil {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 15px;
    }
}

@include breakpoint(medium only) {
    h1{
        font-size: 1.5rem;
    }
    header .nav-principale .menu ul li a{
        @include font-size (10px);
    }
}

@include breakpoint(small down) {
    .intro_accueil {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 15px;
    }
    .prevoir ul {
        margin-left: 0;
        li {
            @include font-size(18px);
        }
    }
    .facebook {
        margin-bottom: 20px;
    }
    h2 {
        @include font-size(30px);
    }
    .navigation--button {
        display: block;
    }
    .menu {
        @include transition(height .5s);
        overflow: hidden;
        height: 0px;
    }
    .menu_visible {
        height: 470px !important;
        @include transition(height .3s);
    }
    .nav-principale {
        height: auto!important;
        img {
            display: block;
            margin: 0 auto;
            width: 50%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        ul {
            margin: 0;
            .menu {
                margin: 0;
                padding: 0;
                ul {
                    li {
                        display: block;
                        width: 100%;
                        text-align: center;
                        float: none!important;
                        a {
                            margin: 0;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            @include transition (.3s);
                            &:hover {
                                background: $primary;
                                color: $snow;
                            }
                        }
                        .active {
                            background: $primary;
                            color: $snow;
                        }
                    }
                }
            }
        }
    }
}
