@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-left-radius($radius) {
    -webkit-border-left-radius: $radius;
    -moz-border-left-radius: $radius;
    -ms-border-left-radius: $radius;
    border-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    -webkit-border-right-radius: $radius;
    -moz-border-right-radius: $radius;
    -ms-border-right-radius: $radius;
    border-right-radius: $radius;
}


@mixin border-top-radius($radius) {
    -webkit-border-top-radius: $radius;
    -moz-border-top-radius: $radius;
    -ms-border-top-radius: $radius;
    border-top-radius: $radius;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}


@mixin filter($filter-type,$filter-amount) { 
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}



/// Text Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [2px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color

@mixin text-shadow($x: 3px, $y: 3px, $blur: 5px, $color: rgba(0,0,0,.2)) {
    text-shadow: $x $y $blur $color;
}



@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}






@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
 @if $inset {
   -webkit-box-shadow:inset $top $left $blur $color;
   -moz-box-shadow:inset $top $left $blur $color;
   box-shadow:inset $top $left $blur $color;
 } @else {
   -webkit-box-shadow: $top $left $blur $color;
   -moz-box-shadow: $top $left $blur $color;
   box-shadow: $top $left $blur $color;
 }
}


@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}




@mixin degrade{
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&amp;1+37,0+100 */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 37%, rgba(255,255,255,0) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(37%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,0) 100%); /* IE10+ */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,0) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */


}






@mixin radial-gradient($from, $to) {
  background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $from), color-stop(100%, $to));
  background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
  background: radial-gradient(center, circle cover, $from 0%, $to 100%);
  background-color: $from;
}

// MIXIN GRADIENT LINEAR + DIRECTION
// MIXIN GRADIENT LINEAR + DIRECTION
// MIXIN GRADIENT LINEAR + DIRECTION
// MIXIN GRADIENT LINEAR + DIRECTION

// ----
// Sass (v3.4.7)
// Compass (v1.0.1)
// ----

/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }
  
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  
  $conversion-map: ( 
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );
    
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  
  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient 
/// as well as a plain color fallback 
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }
  
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}




// EXEMPLES
//  .test-1 {
// @include linear-gradient(#31B7D7, #EDAC7D);
//  }
//  
//  .test-2 {
// @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
//  }
//  
//  .test-3 {
// @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
//  }



// MIXIN GRADIENT LINEAR + DIRECTION FIN
// MIXIN GRADIENT LINEAR + DIRECTION FIN
// MIXIN GRADIENT LINEAR + DIRECTION FIN
// MIXIN GRADIENT LINEAR + DIRECTION FIN








/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}






//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================

$heartbeat-color: #636363;
$heartbeat-size: 10px; // must be divisible by 2 and 3
$heartbeat-rotation: rotate(45deg);

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content }
  @-moz-keyframes #{$name} { @content }
  @-o-keyframes #{$name} { @content }
  @keyframes #{$name} { @content }
}

@mixin experimental($property, $value, $moz: true, $webkit: true, $o: true, $ms: true, $khtml: true, $official: true) {
  @if $webkit   { -webkit-#{$property} : $value; }
  @if $khtml    {  -khtml-#{$property} : $value; }
  @if $moz      {    -moz-#{$property} : $value; }
  @if $ms       {     -ms-#{$property} : $value; }
  @if $o        {      -o-#{$property} : $value; }
  @if $official {         #{$property} : $value; }
}

@mixin animation($value) {
  @include experimental(animation, $value);
}

@include keyframes(heartbeat) {
  0%  { @include transform($heartbeat-rotation scale(1));   }
  14% { @include transform($heartbeat-rotation scale(1.3)); }
  28% { @include transform($heartbeat-rotation scale(1));   }
  42% { @include transform($heartbeat-rotation scale(1.3)); }
  70% { @include transform($heartbeat-rotation scale(1));   }
}



/* Styles for old versions of IE */
.heartbeat {
  font-family: sans-serif;
  font-weight: 100;
  margin-left: 5px;
  margin-right: 5px;
}

/* :not(:required) hides this rule from IE9 and below */
.heartbeat:not(:required) {
  @include animation(heartbeat 2300ms ease 0s infinite normal);
  
  display: inline-block;
  position: relative;  
  overflow: hidden;
  text-indent: -9999px;
  width: $heartbeat-size;  
  height: $heartbeat-size;
  @include transform($heartbeat-rotation scale(1));
  @include transform-origin(50%); 
  
  &:after, &:before {
    position: absolute;
    content: "";  
    background: $heartbeat-color;
  }
  
  &:before {
    @include border-left-radius($heartbeat-size / 3);
    top: ($heartbeat-size/3);
    left: 0;
    width: $heartbeat-size;
    height: (2 * $heartbeat-size / 3);
  }
  
  &:after {
    @include border-top-radius($heartbeat-size / 3);
    top: 0;
    left: ($heartbeat-size / 3);
    width: (2 * $heartbeat-size / 3);
    height: ($heartbeat-size / 3);
  }
}


//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================
//HEART BEAT ==================================================================